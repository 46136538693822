import { Form, FormikComputedProps, FormikProps } from "formik";
import { Translation } from "../../../../../services/translation/translation.type";
import { FC } from "react";
import { GenericForm } from "../../../../../utils/components/form";
import { Button } from "@material-ui/core";
import { MyTextField } from "../../../../../components/common/form/MyTextField";
import { useTranslation } from "react-i18next";
import { MyButton } from "../../../../../components/common/MyButton";

interface TranslationFormProps
  extends FormikProps<Translation>,
    FormikComputedProps<Translation>,
    Partial<GenericForm> {}

export const TranslationForm: FC<TranslationFormProps> = () => {
  const { t } = useTranslation("backoffice");

  return (
    <Form noValidate>
      <MyTextField
        name="translationKey.key"
        label={t("settings.languagesSettings.translationKeys.form.key")}
        disabled
      />
      <MyTextField
        name="translation"
        label={t("settings.languagesSettings.translationKeys.form.text")}
        multiline
        rows={4}
      />
      <MyButton type="submit" variant="contained" color="primary">
        {t("settings.languagesSettings.translationKeys.form.submit")}
      </MyButton>
    </Form>
  );
};
