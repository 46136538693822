import { Box, createStyles, makeStyles, TextField } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDebounce } from "use-debounce/lib";
import { DebouncedState } from "use-debounce/lib/useDebouncedCallback";
import { MyIconButton } from "../../MyIconButton";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: 300,
      margin: 0
    }
  })
);

interface MaterialTableSearchProps {
  onChange: (search: string) => void | DebouncedState<(search: string) => void>;
  classes?: ReturnType<typeof useStyles>;
}

export const MaterialTableSearchRTK: React.FC<MaterialTableSearchProps> = ({
  onChange
}) => {
  const classes = useStyles();
  const { t } = useTranslation("components");
  const location = useLocation();
  const searchRef = useRef(null);

  const [search, setSearch] = useState<string>(
    new URLSearchParams(location.search).get("search")?.toString() || ""
  );

  const onChangeSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearch(event.target.value);
  };

  const onClearSearch = () => {
    setSearch("");
  };

  const [searchDebounce] = useDebounce(search, 850);

  useEffect(() => {
    onChange(searchDebounce);
  }, [onChange, searchDebounce]);

  return (
    <TextField
      autoFocus={search.length > 0}
      ref={searchRef}
      className={classes.root}
      InputProps={{
        startAdornment: (
          <Box mr={1} clone>
            <SearchIcon />
          </Box>
        ),
        endAdornment: search.length ? (
          <MyIconButton onClick={onClearSearch} size="small">
            <ClearIcon />
          </MyIconButton>
        ) : null
      }}
      value={search}
      placeholder={t("myMaterialTable.toolbar.searchPlaceholder")}
      fullWidth
      margin="dense"
      name="search"
      variant="outlined"
      onChange={onChangeSearch}
    />
  );
};
