import { Tooltip } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { MyTypography, MyTypographyProps } from "./MyTypography";

export interface TextOverflowProps extends MyTypographyProps {
  text: string;
  lineClamp?: number;
  tooltip?: boolean;
}

export const TextOverflow: React.FC<TextOverflowProps> = ({
  text,
  lineClamp = 2,
  tooltip,
  ...myTypographyProps
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = React.useState(false);

  useEffect(() => {
    if (
      tooltip &&
      ref?.current?.scrollHeight &&
      ref.current.clientHeight < ref.current.scrollHeight
    ) {
      setIsOverflow(true);
    }
  }, [tooltip, text]);

  return (
    <Tooltip title={tooltip && isOverflow ? text : ""}>
      <div
        ref={ref}
        style={{
          alignSelf: "center",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: lineClamp,
          wordBreak: "break-all",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        <MyTypography {...myTypographyProps}>{text}</MyTypography>
      </div>
    </Tooltip>
  );
};
