/* eslint-disable no-sequences */
import {
  array,
  ArraySchema,
  boolean,
  number,
  NumberSchema,
  object,
  string
} from "yup";
import { SideName } from "../../../../../../entities/brief";
import {
  PrintMethodName,
  StampingMethodName
} from "../../../../../../entities/decoration";
import { InkParameter } from "../../../../../../entities/inkParameter";
import { InputOptionsAdvanced } from "../../../../../../reducers/configurator-input-options/entity";
import { ConfiguratorInputNames } from "../../../../../../reducers/configurator-inputs/constant";
import {
  applyInputOptionsLogic,
  applyInputOptionsLogicBoolean,
  applyInputOptionsLogicBooleanWhichShouldBeTrueIfRequired,
  applyInputOptionsLogicNumber,
  applyInputOptionsLogicObject,
  applyInputOptionsLogicString
} from "../../../../../../utils/yup";
import { LayoutSchemaWithOptions } from "./layouCount.schema";

export type InkParamsSchemaType = ArraySchema<Partial<InkParameter>>;

export const InkParamsSchemaWithOptions = (
  required: boolean,
  options?: InputOptionsAdvanced
): InkParamsSchemaType => {
  let coverageSchema: NumberSchema = number().min(0); // .max(1, i18n.t("yup:number.maxPercentage"));
  if (options?.object?.coverage?.min) {
    coverageSchema = coverageSchema.min(options?.object?.coverage?.min);
  }
  if (options?.object?.coverage?.max) {
    coverageSchema = coverageSchema.max(options?.object?.coverage?.max);
  }

  let inkIdSchema = string();

  if (required) {
    coverageSchema = coverageSchema.required();
    inkIdSchema = inkIdSchema.required();
  }

  return array()
    .nullable(!required)
    .of(
      object({
        inkId: inkIdSchema,
        coverage: coverageSchema,
        position: number().min(0)
      })
    )
    .min(required ? 1 : 0);
};

export const HoststampingParamsWithOptions = (
  required: boolean,
  options?: InputOptionsAdvanced
) => {
  let widthSchema = number().min(0);
  if (options?.object?.width?.min) {
    widthSchema = widthSchema.min(options.object.width.min);
  }
  if (options?.object?.width?.max) {
    widthSchema = widthSchema.max(options.object.width.max);
  }

  let heightSchema = number().min(0);
  if (options?.object?.width?.min) {
    heightSchema = heightSchema.min(options.object.width.min);
  }
  if (options?.object?.width?.max) {
    heightSchema = heightSchema.max(options.object.width.max);
  }

  let colorSchema = string();

  if (required) {
    widthSchema = widthSchema.required();
    heightSchema = heightSchema.required();
    colorSchema = colorSchema.required();
  }

  return array()
    .nullable(!required)
    .of(
      object({
        width: widthSchema,
        height: heightSchema,
        color: colorSchema,
        position: number().min(0)
      })
    )
    .min(required ? 1 : 0);
};

export const DecorationSchema = {
  wantDecoration: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_DECORATION,
    boolean().default(false)
  ),
  referencesCount: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.REFERENCES_COUNT,
    number().default(0)
  ),
  sidePrinted: applyInputOptionsLogicString(
    ConfiguratorInputNames.SIDE_PRINTED,
    string().oneOf([
      SideName.INSIDE,
      SideName.INSIDE_AND_OUTSIDE,
      SideName.OUTSIDE
    ]),
    string()
  ),
  sideVariationPrint: applyInputOptionsLogicString(
    ConfiguratorInputNames.SIDE_VARIATION_PRINT,
    string().oneOf([SideName.INSIDE, SideName.OUTSIDE]),
    string()
  ),
  versoColorTypeCMYK: applyInputOptionsLogicBooleanWhichShouldBeTrueIfRequired(
    ConfiguratorInputNames.VERSO_COLOR_TYPE_CMYK
  ),
  versoDirectToneColorType: applyInputOptionsLogicBooleanWhichShouldBeTrueIfRequired(
    ConfiguratorInputNames.VERSO_DIRECT_TONE_COLOR_TYPE
  ),
  rectoColorTypeCMYK: applyInputOptionsLogicBooleanWhichShouldBeTrueIfRequired(
    ConfiguratorInputNames.RECTO_COLOR_TYPE_CMYK
  ),
  rectoDirectToneColorType: applyInputOptionsLogicBooleanWhichShouldBeTrueIfRequired(
    ConfiguratorInputNames.RECTO_DIRECT_TONE_COLOR_TYPE
  ),
  versoDirectToneColorCount: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.VERSO_DIRECT_TONE_COLOR_COUNT,
    number()
  ),
  rectoDirectToneColorCount: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.RECTO_DIRECT_TONE_COLOR_COUNT,
    number()
  ),
  wantDecorAmalgam: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_DECOR_AMALGAM,
    boolean().default(false)
  ),
  versoColorTypeCMYKOffset: applyInputOptionsLogicBooleanWhichShouldBeTrueIfRequired(
    ConfiguratorInputNames.VERSO_COLOR_TYPE_CMYK_OFFSET
  ),
  versoDirectToneColorTypeOffset: applyInputOptionsLogicBooleanWhichShouldBeTrueIfRequired(
    ConfiguratorInputNames.VERSO_DIRECT_TONE_COLOR_TYPE_OFFSET
  ),
  rectoColorTypeCMYKOffset: applyInputOptionsLogicBooleanWhichShouldBeTrueIfRequired(
    ConfiguratorInputNames.RECTO_COLOR_TYPE_CMYK_OFFSET
  ),
  rectoDirectToneColorTypeOffset: applyInputOptionsLogicBooleanWhichShouldBeTrueIfRequired(
    ConfiguratorInputNames.RECTO_DIRECT_TONE_COLOR_TYPE_OFFSET
  ),
  printMethodVerso: applyInputOptionsLogicString(
    ConfiguratorInputNames.PRINT_METHOD_VERSO,
    string().oneOf([
      PrintMethodName.FLEXOGRAPHY,
      PrintMethodName.OFFSET,
      PrintMethodName.DIGITAL
    ]),
    string()
  ),
  wantPrintOnEdgeVerso: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_PRINT_ON_EDGE_VERSO,
    boolean().default(false)
  ),
  wantFullSurfaceVerso: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_FULL_SURFACE_VERSO,
    boolean().default(false)
  ),
  wantPrintOnCreasingVerso: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_PRINT_ON_CREASING_VERSO,
    boolean().default(false)
  ),
  wantTechnicalPrintingVerso: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_TECHNICAL_PRINTING_VERSO,
    boolean().default(false)
  ),
  flexoPlateInkExistVerso: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.FLEXO_PLATE_INK_EXIST_VERSO,
    boolean().default(false)
  ),
  flexoPlateInkForceVerso: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.FLEXO_PLATE_INK_FORCE_VERSO,
    boolean().default(false)
  ),
  flexoPlateInkLayoutCountVerso: applyInputOptionsLogicObject(
    ConfiguratorInputNames.FLEXO_PLATE_INK_LAYOUT_COUNT_VERSO,
    LayoutSchemaWithOptions
  ),
  cmykParamsVerso: applyInputOptionsLogicObject(
    ConfiguratorInputNames.CMYK_PARAMS_VERSO,
    InkParamsSchemaWithOptions
  ),
  directToneParamsVerso: applyInputOptionsLogicObject(
    ConfiguratorInputNames.DIRECT_TONE_PARAMS_VERSO,
    InkParamsSchemaWithOptions
  ),
  cmykParamsOffsetVerso: applyInputOptionsLogicObject(
    ConfiguratorInputNames.CMYK_PARAMS_OFFSET_VERSO,
    InkParamsSchemaWithOptions
  ),
  directToneParamsOffsetVerso: applyInputOptionsLogicObject(
    ConfiguratorInputNames.DIRECT_TONE_PARAMS_OFFSET_VERSO,
    InkParamsSchemaWithOptions
  ),
  printMethodRecto: applyInputOptionsLogicString(
    ConfiguratorInputNames.PRINT_METHOD_RECTO,
    string().oneOf([
      PrintMethodName.FLEXOGRAPHY,
      PrintMethodName.OFFSET,
      PrintMethodName.DIGITAL
    ]),
    string()
  ),
  wantPrintOnEdgeRecto: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_PRINT_ON_EDGE_RECTO,
    boolean().default(false)
  ),
  wantFullSurfaceRecto: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_FULL_SURFACE_RECTO,
    boolean().default(false)
  ),
  wantPrintOnCreasingRecto: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_PRINT_ON_CREASING_RECTO,
    boolean().default(false)
  ),
  wantTechnicalPrintingRecto: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_TECHNICAL_PRINTING_RECTO,
    boolean().default(false)
  ),
  cmykParamsRecto: applyInputOptionsLogicObject(
    ConfiguratorInputNames.CMYK_PARAMS_RECTO,
    InkParamsSchemaWithOptions
  ),
  directToneParamsRecto: applyInputOptionsLogicObject(
    ConfiguratorInputNames.DIRECT_TONE_PARAMS_RECTO,
    InkParamsSchemaWithOptions
  ),
  cmykParamsOffsetRecto: applyInputOptionsLogicObject(
    ConfiguratorInputNames.CMYK_PARAMS_OFFSET_RECTO,
    InkParamsSchemaWithOptions
  ),
  directToneParamsOffsetRecto: applyInputOptionsLogicObject(
    ConfiguratorInputNames.DIRECT_TONE_PARAMS_OFFSET_RECTO,
    InkParamsSchemaWithOptions
  ),
  flexoPlateInkExistRecto: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.FLEXO_PLATE_INK_EXIST_RECTO,
    boolean().default(false)
  ),
  flexoPlateInkForceRecto: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.FLEXO_PLATE_INK_FORCE_RECTO,
    boolean().default(false)
  ),
  flexoPlateInkLayoutCountRecto: applyInputOptionsLogicObject(
    ConfiguratorInputNames.FLEXO_PLATE_INK_LAYOUT_COUNT_RECTO,
    LayoutSchemaWithOptions
  ),
  screenPrintingRecto: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.SCREENPRINTING_RECTO,
    boolean().default(false)
  ),
  screenPrintingVerso: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.SCREENPRINTING_VERSO,
    boolean().default(false)
  ),
  directToneParamsScreenPrintingRecto: applyInputOptionsLogicObject(
    ConfiguratorInputNames.DIRECT_TONE_PARAMS_SCREENPRINTING_RECTO,
    InkParamsSchemaWithOptions
  ),
  directToneParamsScreenPrintingVerso: applyInputOptionsLogicObject(
    ConfiguratorInputNames.DIRECT_TONE_PARAMS_SCREENPRINTING_VERSO,
    InkParamsSchemaWithOptions
  ),
  wantStampingRecto: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_STAMPING_RECTO,
    boolean().default(false)
  ),
  stampingMethodRecto: applyInputOptionsLogicString(
    ConfiguratorInputNames.STAMPING_METHOD_RECTO,
    string().oneOf([
      StampingMethodName.HOT_STAMPING,
      StampingMethodName.POLYESTER
    ]),
    string()
  ),
  hotstampingParamsRecto: applyInputOptionsLogicObject(
    ConfiguratorInputNames.HOTSTAMPING_PARAMS_RECTO,
    HoststampingParamsWithOptions
  ),
  polyesterLaminationIdRecto: applyInputOptionsLogic(
    ConfiguratorInputNames.POLYESTER_LAMINATION_ID_RECTO,
    string()
  ),
  wantStampingVerso: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_STAMPING_VERSO,
    boolean().default(false)
  ),
  stampingMethodVerso: applyInputOptionsLogicString(
    ConfiguratorInputNames.STAMPING_METHOD_VERSO,
    string().oneOf([
      StampingMethodName.HOT_STAMPING,
      StampingMethodName.POLYESTER
    ]),
    string()
  ),
  hotstampingParamsVerso: applyInputOptionsLogicObject(
    ConfiguratorInputNames.HOTSTAMPING_PARAMS_VERSO,
    HoststampingParamsWithOptions
  ),
  polyesterLaminationIdVerso: applyInputOptionsLogic(
    ConfiguratorInputNames.POLYESTER_LAMINATION_ID_VERSO,
    string()
  ),
  digitalPrintingModeRectoId: applyInputOptionsLogicString(
    ConfiguratorInputNames.DIGITAL_PRINTING_MODE_RECTO_ID,
    string()
  ),
  digitalPrintingModeVersoId: applyInputOptionsLogicString(
    ConfiguratorInputNames.DIGITAL_PRINTING_MODE_VERSO_ID,
    string()
  ),
  digitalPrintingTypeRectoId: applyInputOptionsLogicString(
    ConfiguratorInputNames.DIGITAL_PRINTING_TYPE_RECTO_ID,
    string()
  ),
  digitalPrintingTypeVersoId: applyInputOptionsLogicString(
    ConfiguratorInputNames.DIGITAL_PRINTING_TYPE_VERSO_ID,
    string()
  ),
  cmykwParamsDigitalRecto: applyInputOptionsLogicObject(
    ConfiguratorInputNames.CMYKW_PARAMS_DIGITAL_RECTO,
    InkParamsSchemaWithOptions
  ),
  cmykwParamsDigitalVerso: applyInputOptionsLogicObject(
    ConfiguratorInputNames.CMYKW_PARAMS_DIGITAL_VERSO,
    InkParamsSchemaWithOptions
  ),
  lithoLaminationMaterialReferenceRectoId: applyInputOptionsLogicString(
    ConfiguratorInputNames.LITHO_LAMINATION_MATERIAL_REFERENCE_RECTO_ID,
    string()
  ),
  lithoLaminationMaterialReferenceVersoId: applyInputOptionsLogicString(
    ConfiguratorInputNames.LITHO_LAMINATION_MATERIAL_REFERENCE_VERSO_ID,
    string()
  ),
  lithoLaminationExtraBorderRecto: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.LITHO_LAMINATION_EXTRA_BORDER_RECTO,
    number()
  ),
  lithoLaminationExtraBorderVerso: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.LITHO_LAMINATION_EXTRA_BORDER_VERSO,
    number()
  )
};
