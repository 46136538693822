import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  QueryDefinition,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from "@reduxjs/toolkit/dist/query";
import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { ReactElement } from "react";
import { fp } from "../../../utils/fp";
import { ErrorComponent } from "../DynamicElement/ErrorComponent";
import { NoDataComponent } from "../DynamicElement/NoDataComponent";
import { useHistory } from "react-router";
import { TablePagination } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import {
  PageMetaDtoCrm,
  PageMetaDto,
  AdditionalTransform
} from "../../../services/common/types";
import { dynamicUrl } from "../../../utils/function/dynamicUrl";
import { useUpdateEffect } from "../../../utils/hooks/useUpdateEffect";
import { useTranslation } from "react-i18next";
import { usePrevious } from "../../../utils/hooks/usePrevious";
import { differenceWith, isEqual, toPairs } from "lodash";
import { EmptyState, EmptyStateContext } from "../EmptyState";
import { ref } from "yup";
import { on } from "events";
import { LoadingPage } from "../../../pages/LoadingPage";

export interface DyanmicPaginationProps<T, K extends string> {
  useQuery: UseQuery<
    QueryDefinition<
      Partial<PageMetaDtoCrm>,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        Record<string, never>,
        FetchBaseQueryMeta
      >,
      K,
      {
        data: T;
        meta: PageMetaDto;
      },
      "api"
    >
  >;
  hasFilter?: boolean;
  prefetch?: any;
  withPrefetch?: boolean;
  filterData?: any;
  prefix: string;
  recall?: boolean;
  additionalParam?: Partial<PageMetaDtoCrm>;
  additionalTransform?: Partial<AdditionalTransform>;
  defaultOrder?: string;
  context?: EmptyStateContext;
  setOpenAddElement?: React.Dispatch<React.SetStateAction<boolean>>;
  children: (
    props: NonNullable<T>,
    meta: PageMetaDto,
    path: {
      search: string;
      setSearch: React.Dispatch<React.SetStateAction<string>>;
      orderBy: string;
      setOrderBy: React.Dispatch<React.SetStateAction<string>>;
      isSuccess: boolean;
      isFetching: boolean;
    }
  ) => ReactElement;
}

const DynamicElementComponents = {
  loader: LoadingPage,
  noData: NoDataComponent,
  error: ErrorComponent
};

/**
 * Component for pagination
 * 
 * TODO : Needs to improve route path with search, order and additional Params
 * 
 * @param useQuery Hooks from RTKQ service
 * @param children JSX element corresponding to a table component
 * @param prefix Path of the current route associated to pagination
 * @param recall  Allows forcing the query to always refetch on mount (when true is provided). Allows forcing the query to refetch if enough time (in seconds) has passed since the last query for the same cache (when a number is provided). Defaults to false and automatically refetch if page = 1
 * @param filterData to allows altering the returned value of the hook to obtain a subset of the result, render-optimized for the returned subset.
 * @param additionalParam Additional params for pagination that are not included in the component
 * @param defaultOrder Allows to determine which column will be sorted at first rendering
 * @param prefetch Hooks from RTKQ service to prefetch next page
 * @example <DyanmicPagination
        useQuery={useGetProjectsQuery}
        prefix={"/back-office/projects"}
        defaultOrder={"+createdAt"}
        prefetch={useProjectRefetch("getProjects")}
        additionalParam={{
          ...(projectStatus && {
            status: projectStatus
          })
        }}
        recall
      >
        {(data, { itemCount }, { orderBy, setOrderBy, search, setSearch }) => (
          <ProjectTable
            hideCreateButton
            updateStatus={setProjectStatus}
            currentStatus={projectStatus}
            data={data as any[]}
            totalItems={itemCount}
            orderRTK={{ orderBy, setOrderBy }}
            searchRTK={{ search, setSearch }}
            tableProps={(props) => {
              props.emptyStateProps = {
                context: EmptyStateContext.PROJECT
              };
              props.addElement = {
                title: t("projects.form.create.title"),
                buttonLabel: t("projects.form.create.title"),
                status: projectCreateStatus,
                formik: {
                  children: (props) => (
                    <ProjectForm
                      status={projectCreateStatus}
                      admin
                      {...props}
                    />
                  ),
                  validationSchema: ProjectSchema.CREATE,
                  initialValues: {
                    name: undefined,
                    companyId: undefined,
                    managerId: currentUser.id,
                    estimatedValues: null,
                    dueDate: null
                  },
                  onSubmit: onCreateProject
                },
                buttonProps: { id: "button-new-project" }
              };
              return props;
            }}
          ></ProjectTable>
        )}
      </DyanmicPagination>
 * @returns An object with 3 keys 

      data : Paging results

      meta : Metadata associated with pagination (eg : itemCount, page ...)

      basic hooks : To update descending tree component (eg: search, order ...)
 */

export function DyanmicPagination<T, K extends string>({
  useQuery,
  children,
  recall = false,
  filterData,
  additionalParam,
  defaultOrder,
  prefetch,
  context,
  hasFilter,
  setOpenAddElement,
  withPrefetch
}: DyanmicPaginationProps<T, K>): JSX.Element | null {
  const location = useLocation();
  const history = useHistory();

  const prefetchTimeout: NodeJS.Timeout | null = null;

  const { t } = useTranslation("backoffice");

  const isBookmarks = new URLSearchParams(window.location.search).get(
    "bookmarks"
  );

  const [page, setPage] = useState(
    parseInt(new URLSearchParams(location.search).get("page") as string) || 1
  );

  const [isPageChanging, setIsPageChanging] = useState(false);

  const [limit, setLimit] = useState(
    parseInt(new URLSearchParams(location.search).get("limit") as string) || 25
  );

  const [search, setSearch] = useState<string>(
    new URLSearchParams(location.search).get("search")?.toString() || ""
  );

  const [orderBy, setOrderBy] = useState<string>(
    new URLSearchParams(location.search).get("sort")?.toString() ||
      (defaultOrder as string)
  );

  const tab = new URLSearchParams(location.search).get("tab");

  const { userId, projectId, companyId, contactId, ...params } = useMemo(
    () => (additionalParam as Partial<PageMetaDtoCrm>) || {},
    [additionalParam]
  );

  const prevParams = usePrevious({
    ...params,
    search,
    orderBy,
    limit,
    page,
    tab
  });

  const { data, isLoading, isError, isSuccess, isFetching } = useQuery(
    {
      page,
      limit,
      ...(search && {
        search: search
      }),
      ...(orderBy && {
        sort: orderBy
      }),
      ...additionalParam
    },
    {
      ...(filterData && {
        selectFromResult: (result) => {
          return filterData(result);
        }
      }),
      refetchOnMountOrArgChange: recall || page === 1,
      skip: isPageChanging
    }
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setIsPageChanging(true);
    setPage(newPage + 1);
  };

  useEffect(() => {
    if (isPageChanging) {
      const timer = setTimeout(() => {
        setIsPageChanging(false);
      }, 150); // Adjust the delay as needed (now 150 for slow click)
      return () => clearTimeout(timer);
    }
  }, [isPageChanging]);

  const handleChangeRowsPerPage = (event: any) => {
    const newLimit = parseInt(event.target.value, 10);
    setLimit(newLimit);
    setPage(1);
  };

  const prefetchNext = useCallback(() => {
    if (!prefetch) return;

    prefetch({
      page: page + 1,
      limit,
      ...(search && {
        search: search
      }),
      ...(orderBy && {
        sort: orderBy
      }),
      ...additionalParam
    });
  }, [prefetch, page, limit, search, orderBy, additionalParam]);

  useEffect(() => {
    if (
      page !== data?.meta?.pageCount &&
      data?.meta?.pageCount !== 0 &&
      !isLoading
    ) {
      prefetchNext();
    }
  }, [page, data, prefetchNext, isLoading]);

  /* 
  const handleMouseEnter = () => {
    const prefetchWithDelay = () => {
      if (page !== data?.meta?.pageCount && data?.meta?.pageCount !== 0) {
        prefetchNext();
        prefetchTimeout = setTimeout(prefetchWithDelay, 1000); // Adjust delay as needed
      }
    };
    prefetchWithDelay();
  };

  const handleMouseLeave = () => {
    if (prefetchTimeout) {
      clearTimeout(prefetchTimeout);
      prefetchTimeout = null;
    }
  };

   */

  useUpdateEffect(() => {
    const isChanging = differenceWith(
      toPairs({ ...params, search, orderBy, limit, page }),
      toPairs(prevParams as any),
      isEqual
    );

    if (isChanging.length === 0) {
      return;
    }

    history.push({
      search: dynamicUrl("", {
        tab,
        page,
        limit,
        sort: orderBy,
        search: search,
        ...params,
        ...(isBookmarks && {
          bookmarks: true
        })
      })
    });
  }, [defaultOrder, history, limit, orderBy, page, search, params, prevParams]);

  useUpdateEffect(() => {
    const isChanging = differenceWith(
      toPairs({ ...params, search }),
      toPairs(prevParams as any),
      isEqual
    );
    if (isChanging.length === 0) {
      return;
    }
    setPage(1);
  }, [search, params]);

  if (isError) {
    return React.createElement(DynamicElementComponents.error);
  }

  if (isLoading) {
    return React.createElement(DynamicElementComponents.loader);
  }

  if (isSuccess || isPageChanging) {
    if (
      fp.isEmpty(data?.data) &&
      !search &&
      (!isFetching ||
        (isFetching &&
          fp.isEmpty(data?.data) &&
          !Object.values(params).find(Boolean) &&
          !hasFilter)) &&
      !Object.values(params).find(Boolean) &&
      context
    ) {
      return (
        <EmptyState
          onClick={() => setOpenAddElement && setOpenAddElement(true)}
          context={context as EmptyStateContext}
        />
      );
    } else {
      return (
        <div>
          {children(data?.data as NonNullable<T>, data?.meta as PageMetaDto, {
            search,
            setSearch,
            orderBy,
            setOrderBy,
            isSuccess,
            isFetching
          })}
          {data!.meta?.itemCount > 0 && (
            <TablePagination
              /*    nextIconButtonProps={{
                onMouseEnter: () => {
                  if (
                    page !== data?.meta?.pageCount &&
                    data?.meta?.pageCount !== 0
                  ) {
                    prefetchNext();
                  }
                }
              }} */
              component="div"
              count={data?.meta?.itemCount as number}
              // Index of tablepagination starts at 0
              page={page - 1}
              rowsPerPage={limit}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t("pagination.rows-page")}
            />
          )}
        </div>
      );
    }
  }

  return null;
}
