import { FC } from "react";
import { MyBreadcrumbs } from "../../../../components/common/MyBreadcrumbs";
import { Page } from "../../../../components/common/MyPage";
import { useSelector } from "react-redux";
import { getIsAdminUser } from "../../../../reducers/authentication/selector";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DyanmicPagination } from "../../../../components/common/DynamicPagination";
import { useGetPaginationTranslationsQuery } from "../../../../services/translation/translation.service";
import { Translation } from "../../../../services/translation/translation.type";
import { TranslationTable } from "./components/Table";

interface TranslationKeysProps {}

export const TranslationKeys: FC<TranslationKeysProps> = () => {
  const isAdmin = useSelector(getIsAdminUser);
  const { t } = useTranslation("backoffice");
  const { id } = useParams<{ id: string }>();

  return (
    <Page p={{ xs: 1, sm: 4 }}>
      <MyBreadcrumbs
        start="back-office"
        itemName={`${t(
          "settings.languagesSettings.translationKeys.title"
        )}/${id}`}
        links={isAdmin ? [] : ["/back-office/my-companies"]}
        style={{ wordBreak: "break-all" }}
      />
      <DyanmicPagination
        key={id}
        additionalParam={{ lang: id }}
        useQuery={useGetPaginationTranslationsQuery}
        prefix={`/back-office/settings/languages`}
        defaultOrder="updatedAt,DESC"
      >
        {(data, __, { orderBy, setOrderBy, search, setSearch }) => (
          <>
            <TranslationTable
              data={data as Translation[]}
              searchRTK={{
                search,
                setSearch
              }}
              orderRTK={{
                orderBy,
                setOrderBy
              }}
            />
          </>
        )}
      </DyanmicPagination>
    </Page>
  );
};

export default TranslationKeys;
