import produce from "immer";
import merge from "lodash/merge";
import {
  BriefConfiguratorActions,
  BriefConfiguratorActionsTypes
} from "./action";
import { Brief, ConfiguratorContext } from "../../entities/brief";
import { omit } from "lodash";

/** fields to ignore in order to set initial values for the configurator */
export const OmitBriefFieldsForConfiguration: Array<Partial<keyof Brief>> = [
  "id",
  "code",
  "hasPrice",
  "_errorMessage",
  "statusId",
  "status",
  "userId",
  "briefComputedId",
  "createdAt",
  "updatedAt",
  "project",
  "company",
  "user",
  "briefElements",
  "predictionRequest",
  "briefComputed",
  "sampleStatus",
  "dielineStatus",
  "multiUpStatus",
  "parent",
  "root",
  "hasTimeout",
  "palletFormat",
  "deliveryAddressId"
];

export interface BriefConfiguratorValues
  extends Omit<
    Brief,
    | "id"
    | "code"
    | "hasPrice"
    | "_errorMessage"
    | "statusId"
    | "status"
    | "userId"
    | "briefComputedId"
    | "createdAt"
    | "updatedAt"
    | "project"
    | "company"
    | "user"
    | "briefElements"
    | "predictionRequest"
    | "briefComputed"
    | "sampleStatus"
    | "dielineStatus"
    | "multiUpStatus"
    | "parent"
    | "root"
    | "hasTimeout"
  > {
  /** provided if we want to set id from frontend */
  id?: string;
}

export const BriefInitialValues: Brief = {
  id: "",
  code: "",
  name: undefined,
  deliveryDecision: undefined,
  intercom: undefined,
  deliveryAddressId: undefined,
  deliveryAddress: undefined,
  deliveryZipCode: undefined,
  hasPrice: undefined,
  _errorMessage: undefined,
  comment: undefined,
  statusId: undefined,
  status: undefined,
  userId: "",
  briefComputedId: "",
  createdAt: new Date(),
  updatedAt: new Date(),
  projectId: undefined,
  project: undefined,
  company: undefined,
  user: undefined,
  briefElements: undefined,
  predictionRequest: undefined,
  briefComputed: undefined,
  sampleStatus: undefined,
  dielineStatus: undefined,
  multiUpStatus: undefined,
  context: ConfiguratorContext.CREATION,
  parentId: undefined,
  parent: undefined,
  rootId: undefined,
  root: undefined,
  hasTimeout: undefined,
  customFields: undefined,
  packingType: undefined,
  specificBundleRequirement: undefined,
  minQuantityBundle: undefined,
  maxQuantityBundle: undefined,
  minHeightBundle: undefined,
  maxHeightBundle: undefined,
  minWeightBundle: undefined,
  maxWeightBundle: undefined,
  palletType: undefined,
  palletFormatId: undefined,
  palletFormat: undefined,
  palletCustomWidth: undefined,
  palletCustomLength: undefined,
  palletCustomHeight: undefined,
  palletCustomWeight: undefined,
  palletStackable: undefined,
  palletOverhang: undefined,
  maxPalletOverhang: undefined,
  maxPalletHeight: undefined,
  maxPalletUnderload: undefined
};

export const BriefConfiguratorInitialValues = omit(
  BriefInitialValues,
  OmitBriefFieldsForConfiguration
);

export interface BriefConfiguratorState {
  briefElementSelectedPosition: number;
  submitValues: BriefConfiguratorValues;
  initialValues: BriefConfiguratorValues;
  values: BriefConfiguratorValues;
  isValid: boolean;
  isTimeout: boolean;
  submitCount: number;
  hasSubmitOnce: boolean;
  valuesHasChangedAfterSubmit: boolean;
  openSaveBriefDialog: boolean;
  openPredictionPanel: boolean;
  openRequestQuotationNoPriceDialog: boolean;
  openRequestQuotationHipeFirst: boolean;
}

export const BriefConfiguratorInitialState: BriefConfiguratorState = {
  briefElementSelectedPosition: 0,
  submitValues: BriefConfiguratorInitialValues,
  initialValues: BriefConfiguratorInitialValues,
  values: BriefConfiguratorInitialValues,
  isValid: false,
  isTimeout: false,
  submitCount: 0,
  hasSubmitOnce: false,
  valuesHasChangedAfterSubmit: false,
  openSaveBriefDialog: false,
  openPredictionPanel: false,
  openRequestQuotationNoPriceDialog: false,
  openRequestQuotationHipeFirst: false
};

export const BriefConfiguratorReducer = (
  state = BriefConfiguratorInitialState,
  action: BriefConfiguratorActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_SELECT_BRIEF_ELEMENT:
        draft.briefElementSelectedPosition = action.position;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_SUBMIT:
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_SET_SUBMIT_VALUES:
        draft.submitValues = action.submitValues;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_SET_VALUES:
        draft.values = action.values || BriefConfiguratorInitialValues;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_SET_INITIAL_VALUES:
        draft.initialValues =
          action.initialValues || BriefConfiguratorInitialValues;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_UPDATE_VALUES:
        draft.values = { ...draft.values, ...action.values };
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_ON_CHANGE:
        draft.values = action.values;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_SET_SUBMIT_COUNT:
        draft.submitCount = action.count;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_HAS_SUBMIT_ONCE:
        draft.hasSubmitOnce = true;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_HAS_CHANGE_AFTER_SUBMIT:
        draft.valuesHasChangedAfterSubmit = action.hasChange;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_IS_VALID:
        draft.isValid = action.isValid;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_IS_TIMEOUT:
        draft.isTimeout = action.isTimeout;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_OPEN_SAVE_BRIEF_SUCCESS:
        draft.openSaveBriefDialog = action.open;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_OPEN_PREDICTION_PANEL:
        draft.openPredictionPanel = action.open;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_OPEN_REQUEST_QUOTATION_NO_PRICE:
        draft.openRequestQuotationNoPriceDialog = action.open;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_OPEN_REQUEST_HIPE_FIRST:
        draft.openRequestQuotationHipeFirst = action.open;
        break;
      case BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_CLEAR:
        return BriefConfiguratorInitialState;

      default:
        return draft;
    }
  });
