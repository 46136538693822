import { string, object } from "yup";
import { Translation } from "../../../../services/translation/translation.type";
import { EntitySchema } from "../../../../utils/yup";

export const TranslationKeysTablesSchema = EntitySchema<
  Translation,
  "UPDATE" | "CREATE"
>(
  {
    id: string(),
    translation: string()
  },
  {
    id: string().required()
  },
  () => ({
    CREATE: object({
      id: string(),
      key: string(),
      value: string(),
      languageId: string()
    }),
    UPDATE: object({
      id: string(),
      translation: string()
    })
  })
);
