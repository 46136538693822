import { Operation, OperationTranslated } from "../reducers/operations/entity";
import { Machine, MachineTranslated } from "./machine";

export enum PaceMeasureUnit {
  FILE_HOUR = "FILE/HOUR",
  PLATE_HOUR = "PLATE/HOUR",
  SHEETS_HOUR = "SHEETS/HOUR",
  PRODUCTS_HOUR = "PRODUCTS/HOUR",
  COLOR_HOUR = "COLOR/HOUR",
  LINEAR_M_HOUR = "LINEAR METER/HOUR",
  METERS_MIN = "METERS/MINUTE",
  SQUARE_METER_HOUR = "SQUARE_METER/HOUR"
}

export const PaceMeasureTranslation: Record<string, string> = {
  [PaceMeasureUnit.FILE_HOUR]: "settings.rates.paceUnit.fileHour",
  [PaceMeasureUnit.PLATE_HOUR]: "settings.rates.paceUnit.plateHour",
  [PaceMeasureUnit.SHEETS_HOUR]: "settings.rates.paceUnit.sheetsHour",
  [PaceMeasureUnit.PRODUCTS_HOUR]: "settings.rates.paceUnit.productsHour",
  [PaceMeasureUnit.COLOR_HOUR]: "settings.rates.paceUnit.colorHour",
  [PaceMeasureUnit.LINEAR_M_HOUR]: "settings.rates.paceUnit.linearMeterHour",
  [PaceMeasureUnit.METERS_MIN]: "settings.rates.paceUnit.metersHour",
  [PaceMeasureUnit.SQUARE_METER_HOUR]: "settings.rates.paceUnit.squareMeterHour"
};

export enum WasteBaseMeasureUnit {
  SHEETS = "SHEETS",
  PRODUCTS = "PRODUCTS",
  FLAT_PRODUCTS = "FLAT_PRODUCTS",
  BAGS = "BAGS",
  PLATE = "PLATE",
  FILE = "FILE",
  COLOR = "COLOR",
  METERS = "METERS",
  SQUARE_METER = "SQUARE_METERS"
}

export const WasteMeasureTranslation: Record<string, string> = {
  [WasteBaseMeasureUnit.SHEETS]: "settings.rates.wasteUnit.sheets",
  [WasteBaseMeasureUnit.PRODUCTS]: "settings.rates.wasteUnit.products",
  [WasteBaseMeasureUnit.FLAT_PRODUCTS]: "settings.rates.wasteUnit.flatProducts",
  [WasteBaseMeasureUnit.BAGS]: "settings.rates.wasteUnit.bags",
  [WasteBaseMeasureUnit.PLATE]: "settings.rates.wasteUnit.plate",
  [WasteBaseMeasureUnit.FILE]: "settings.rates.wasteUnit.file",
  [WasteBaseMeasureUnit.COLOR]: "settings.rates.wasteUnit.color",
  [WasteBaseMeasureUnit.METERS]: "settings.rates.wasteUnit.meters",
  [WasteBaseMeasureUnit.SQUARE_METER]: "settings.rates.wasteUnit.squareMeter"
};

export class MachinesOperationsRate {
  id!: string;
  selectedRate!: number;
  setupTimeRate!: number;
  setupTimeBase!: number;
  wasteBase!: number;
  wasteRate!: number;
  wasteBaseMeasureUnit!: WasteBaseMeasureUnit;
  pace!: number;
  paceMeasureUnit!: PaceMeasureUnit;
  machineId!: string;
  machine!: Machine;
  operationId!: string;
  operation!: Operation;
}

export interface MachinesOperationsRateTranslated
  extends Omit<MachinesOperationsRate, "machine" | "operation"> {
  machine: MachineTranslated;
  operation: OperationTranslated;
}

export interface MachinesOperationsRateCreateDTO {}

export interface MachinesOperationsRateUpdateDTO {
  id: string;
  selectedRate: number;
  setupTimeRate: number;
  setupTimeBase: number;
  wasteBase: number;
  wasteRate: number;
  pace: number;
  machineId: string;
  operationId: string;
}
