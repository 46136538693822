import { AxiosError } from "axios";
import { AppAddSnackbar } from "../../reducers/app/action";
import { apiSlice } from "../../utils/api/api";
import i18n from "../../utils/i18n";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { Translation, TranslationPagination } from "./translation.type";
import { PageMetaDtoCrm } from "../common/types";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["Translations", "TranslationsPagination"]
});

export interface DtoTranslationUpdate {
  translations: Partial<Translation[]>;
  id: string;
}

const extendedApiSlice = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getTranslationsByKeys: build.query<Translation[], string>({
      query(keys) {
        return {
          url: `/translations/keys?keys=${keys}`,
          method: "GET"
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Translations" as const,
                id
              })),
              { type: "Translations", id: "PARTIAL-LIST" }
            ]
          : [{ type: "Translations", id: "PARTIAL-LIST" }]
    }),
    getTranslationsByKey: build.query<Translation[], string>({
      query(key) {
        return {
          url: `/translations/${key}`,
          method: "GET"
        };
      }
    }),
    updateTranslationByKeys: build.mutation<
      Translation,
      Partial<Translation[]>
    >({
      query(data: Partial<Translation[]>) {
        return {
          url: `/translations`,
          method: "POST",
          body: data
        };
      },
      async onQueryStarted(__, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            new AppAddSnackbar(
              i18n.t("saga:create-success").toString(),
              "success"
            )
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:create-failed").toString()
            )
          );
        }
      }
    }),
    updateTranslationByKey: build.mutation<Translation, DtoTranslationUpdate>({
      query({ translations, id }) {
        return {
          url: `/translations/key/${id}`,
          method: "PATCH",
          body: translations
        };
      },
      async onQueryStarted(__, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            new AppAddSnackbar(
              i18n.t("saga:create-success").toString(),
              "success"
            )
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:create-failed").toString()
            )
          );
        }
      }
    }),
    updateTranslation: build.mutation<Translation, Partial<Translation>>({
      query(data) {
        const { id, ...patch } = data;
        return {
          url: `/translations/${id}`,
          method: "PATCH",
          body: patch
        };
      },
      async onQueryStarted(__, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            new AppAddSnackbar(
              i18n.t("saga:update-success").toString(),
              "success"
            )
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed").toString()
            )
          );
        }
      },
      invalidatesTags: (_, __, data) => [
        { type: "Translations", id: data.id },
        {
          type: "TranslationsPagination",
          id: data.id
        }
      ]
    }),
    getPaginationTranslations: build.query<
      TranslationPagination,
      Partial<PageMetaDtoCrm>
    >({
      query: ({
        page = 1,
        limit = 25,
        sort,
        search,
        date,
        start,
        end,
        filterId,
        lang
      }) => {
        return {
          url: `/translations/pagination`,
          method: "GET",
          params: {
            page,
            limit,
            sort,
            search,
            date,
            start,
            end,
            filterId,
            lang
          }
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }) => ({
                  type: "TranslationsPagination" as const,
                  id
                }),
                { type: "TranslationsPagination", id: "PARTIAL-LIST" }
              )
            ]
          : [{ type: "TranslationsPagination", id: "PARTIAL-LIST" }]
    })
  }),
  overrideExisting: false
});

export const {
  useGetTranslationsByKeysQuery,
  useGetTranslationsByKeyQuery,
  useUpdateTranslationByKeysMutation,
  useUpdateTranslationByKeyMutation,
  useUpdateTranslationMutation,
  useGetPaginationTranslationsQuery,
  usePrefetch: usePrefetchTranslations
} = extendedApiSlice;
